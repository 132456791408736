import React from 'react'
import { Box } from '@mui/material'

export default function MaxWidth({ children }) {
    return (
        <Box
            sx={{
                maxWidth: {
                    xs: '100%',
                    md: 1024,
                },
                margin: '0 auto',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    px: {
                        xs: 2,
                        md: 6,
                    },
                }}
            >
                {children}
            </Box>
        </Box>
    )
}
