import React, { useState } from 'react'
import {
    Box,
    Checkbox,
    Dialog,
    FormControlLabel,
    IconButton,
    Stack,
    Typography,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material'
import { ReactComponent as IconClose } from '../../assets/IconClose.svg'
import { sendData } from '../../functions/DataController'
import { useAppState } from '../../contexts/State'

export default function FormModal({ single = false, productId = null, handleClose, open }) {
    const state = useAppState()

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [industry, setIndustry] = useState('')
    const [title, setTitle] = useState('')
    const [optin, setOptin] = useState(true)

    const [loading, setLoading] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [errors, setErrors] = useState({})

    const handleSendRequest = async () => {
        let products

        if (single) {
            products = productId
        } else {
            let allProd = [...state.products]
            products = allProd
                .filter((prod) => prod.selected)
                .map((prod) => prod.id)
                .join(',')
        }

        const theOptin = optin ? 1 : 0

        setLoading(true)

        sendData({ email, name, company, industry, title, optin: theOptin, products })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    setSubmitSuccess(true)
                    state.setDisplayMode()
                } else {
                    console.error(message, errors)
                    setErrors(errors)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onClose = () => {
        handleClose()

        setEmail('')
        setName('')
        setCompany('')
        setIndustry('')
        setTitle('')
        setOptin(true)
        setSubmitSuccess(false)
    }

    return (
        <Dialog
            open={open}
            maxWidth={{
                xs: 500,
                md: 600,
            }}
        >
            <Box
                sx={{
                    p: {
                        xs: '50px 26px 46px',
                        md: '55px 70px 50px',
                    },
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 12,
                            md: 17,
                        },
                        right: {
                            xs: 12,
                            md: 17,
                        },
                    }}
                >
                    <IconButton onClick={onClose}>
                        <IconClose
                            width={15}
                            height={15}
                        />
                    </IconButton>
                </Box>

                {!submitSuccess && (
                    <Stack spacing="30px">
                        <Typography
                            variant="h2"
                            color="primary"
                            textAlign="center"
                        >
                            Send product detail sheets
                        </Typography>
                        <Stack spacing="10px">
                            <TextField
                                variant="outlined"
                                placeholder="Full Name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                    setErrors((val) => ({ ...val, name: false }))
                                }}
                                error={!!errors['name']}
                                helperText={errors['name']}
                            />
                            <TextField
                                variant="outlined"
                                placeholder="Email Address"
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    setErrors((val) => ({ ...val, email: false }))
                                }}
                                error={!!errors['email']}
                                helperText={errors['email']}
                            />
                        </Stack>
                        <Stack spacing="10px">
                            <TextField
                                variant="outlined"
                                placeholder="Company"
                                value={company}
                                onChange={(e) => {
                                    setCompany(e.target.value)
                                    setErrors((val) => ({ ...val, company: false }))
                                }}
                                error={!!errors['company']}
                                helperText={errors['company']}
                            />
                            <TextField
                                variant="outlined"
                                placeholder="Industry Type"
                                value={industry}
                                onChange={(e) => {
                                    setIndustry(e.target.value)
                                    setErrors((val) => ({ ...val, industry: false }))
                                }}
                                error={!!errors['industry']}
                                helperText={errors['industry']}
                            />
                            <TextField
                                variant="outlined"
                                placeholder="Job Title"
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                    setErrors((val) => ({ ...val, title: false }))
                                }}
                                error={!!errors['title']}
                                helperText={errors['title']}
                            />
                        </Stack>
                        <Stack>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={optin}
                                        onChange={() => setOptin((val) => !val)}
                                    />
                                }
                                color="primary"
                                label={
                                    <Typography
                                        variant="body1"
                                        color="primary"
                                        sx={{
                                            fontSize: 14,
                                            lineHeight: '18.2px',
                                        }}
                                    >
                                        I would like to hear updates on Chobani® news and products
                                    </Typography>
                                }
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSendRequest}
                                disabled={loading}
                            >
                                {loading && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'relative',
                                                top: 4,
                                            }}
                                            color="primary"
                                        />
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        opacity: loading ? 0 : 1,
                                    }}
                                >
                                    Send to me
                                </Box>
                            </Button>
                        </Stack>
                    </Stack>
                )}

                {submitSuccess && (
                    <Stack spacing="30px">
                        <Typography
                            variant="h2"
                            color="primary"
                            textAlign="center"
                        >
                            Email successfully sent
                            <br />
                            Thank you!
                        </Typography>

                        <Stack
                            direction="row"
                            justifyContent="center"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onClose}
                            >
                                Keep browsing
                            </Button>
                        </Stack>
                    </Stack>
                )}
            </Box>
        </Dialog>
    )
}
