import { Box, Dialog } from '@mui/material'
import React from 'react'
import { Slide } from '@mui/material'
import PDPHeader from './PDPHeader'
import Main from '../layout/Main'
import PDPFooter from './PDPFooter'
import MaxWidth from '../shared/MaxWidth'

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={1000}
            {...props}
        />
    )
})

export default function PDP({ product, open, handleClose }) {
    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
        >
            <PDPHeader
                name={product.name}
                handleClose={handleClose}
            />

            {product.sheets && product.sheets.length > 0 && (
                <MaxWidth>
                    <Main>
                        {product.sheets.map((sheet, index) => (
                            <Box
                                key={index}
                                sx={{
                                    '& img': {
                                        display: 'block',
                                        width: '100%',
                                        height: 'auto',
                                        maxWidth: 800,
                                        margin: '0 auto 20px',
                                    },
                                }}
                            >
                                <img src={sheet} />
                            </Box>
                        ))}
                    </Main>
                </MaxWidth>
            )}

            <PDPFooter productId={product.id} />
        </Dialog>
    )
}
