import { ThemeProvider } from '@mui/system'
import { AppStateProvider } from './contexts/State'
import PLP from './components/plp/PLP'
import theme from './theme/theme'

function App() {
    return (
        <AppStateProvider>
            <ThemeProvider theme={theme}>
                <div className="App">
                    <PLP />
                </div>
            </ThemeProvider>
        </AppStateProvider>
    )
}

export default App
