import { createTheme } from '@mui/material/styles'

const headingStyles = {
    fontFamily: '"Chobani Serif", serif',
    lineHeight: 1,
}

const defaults = {
    letterSpacing: 0,
}

const palette = {
    primary: {
        100: '#1A3C344D', // borders etc
        150: '#1A3C34CC', // modal backdrop
        200: '#1A3C3480', // faded font
        main: '#1A3C34',
        600: '#1B3A32',
    },

    secondary: {
        100: '#B9C6B980',
        main: '#B9C6B9',
    },

    offWhite: {
        main: '#F4F3F080', // product background
    },

    error: {
        main: '#E2261C',
    },
}

const breakpoints = {
    values: {
        xs: 0, // default phones, iPhone SE
        sm: 400, // larger/newer iPhones
        md: 700, // tablets
        lg: 1024, // large tablets, desktop
    },
}

const theme = createTheme({
    palette,
    breakpoints,

    typography: {
        body1: {
            ...defaults,
            fontSize: 9,
            fontFamily: '"Chobani Sans", sans',

            [`@media (min-width: ${breakpoints.values.md}px)`]: {
                fontSize: 14,
            },
        },

        body2: {
            ...defaults,
            fontSize: 14,
            lineHeight: 1.4,
            fontFamily: '"Chobani Serif", serif',

            [`@media (min-width: ${breakpoints.values.md}px)`]: {
                fontSize: 16,
            },
        },

        h1: {
            ...defaults,
            fontSize: 32,
            ...headingStyles,
            letterSpacing: -0.5,

            [`@media (min-width: ${breakpoints.values.md}px)`]: {
                fontSize: 50,
                letterSpacing: -1,
            },
        },

        h2: {
            ...defaults,
            fontSize: 22,
            ...headingStyles,

            [`@media (min-width: ${breakpoints.values.md}px)`]: {
                fontSize: 32,
            },
        },

        h3: {
            ...defaults,
            fontSize: 14,
            ...headingStyles,

            [`@media (min-width: ${breakpoints.values.md}px)`]: {
                fontSize: 22,
            },
        },

        h4: {
            ...defaults,
            fontSize: 18,
            ...headingStyles,
        },

        small: {
            letterSpacing: 0.25,
            fontSize: 9,
        },
    },

    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: '"Chobani Sans", sans',
                    fontSize: 14,
                    minHeight: 38,
                    padding: '0 30px',
                    letterSpacing: 0,
                },
            },
        },

        MuiOutlinedInput: {
            defaultProps: {
                color: 'primary',
            },

            styleOverrides: {
                root: {
                    fontFamily: "'Chobani Serif', serif",
                    fontSize: 16,
                    lineHeight: 1,
                },

                input: {
                    padding: '10px',

                    '&::placeholder': {
                        color: palette.primary[200],
                        opacity: 1,
                    },
                },

                notchedOutline: {
                    borderColor: palette.primary[200],
                },
            },
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    fontSize: 12,
                    lineHeight: 1,
                    fontFamily: '"Chobani Sans", sans-serif',
                },
            },
        },

        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.primary[150],
                },
            },
        },

        MuiDialog: {
            styleOverrides: {
                paper: {
                    margin: 16,
                },

                paperFullScreen: {
                    margin: 0,
                },
            },
        },
    },
})

export default theme
