const url = 'https://chobani-services.reconstrukt.net/api/v1/expo/request'

export const sendData = ({ email, name, company, industry, title, optin, products }) => {
    const formData = new FormData()

    formData.append('email', email)
    formData.append('name', name)
    formData.append('company', company)
    formData.append('industry', industry)
    formData.append('title', title)
    formData.append('optin', optin)
    formData.append('products', products)

    const opts = {
        method: 'POST',
        body: formData,
    }

    return fetch(url, opts).then((response) => response.json())
}
