import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import RoundButton from '../shared/RoundButton'
import FormModal from '../shared/FormModal'
import MaxWidth from '../shared/MaxWidth'

export default function PDPFooter({ productId }) {
    const [open, setOpen] = useState(false)

    return (
        <Box
            sx={{
                borderTop: '1px solid',
                borderTopColor: 'primary.100',
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                minHeight: {
                    xs: 70,
                    md: 106,
                },
                backgroundColor: 'white',
                zIndex: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <MaxWidth>
                <Stack
                    justifyContent="center"
                    sx={{
                        py: {
                            xs: 1.5,
                            md: 2,
                        },
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing="10px"
                        sx={{
                            minHeight: '100%',
                        }}
                    >
                        <RoundButton
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={() => setOpen(true)}
                        >
                            Send product detail sheets
                        </RoundButton>

                        <FormModal
                            single={true}
                            productId={productId}
                            open={open}
                            handleClose={() => setOpen(false)}
                        />
                    </Stack>
                </Stack>
            </MaxWidth>
        </Box>
    )
}
