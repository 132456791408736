import { Box } from '@mui/material'
import React from 'react'

export default function Main({ children }) {
    return (
        <Box
            sx={{
                pt: '70px',
                pb: '106px',
            }}
        >
            <Box
                sx={{
                    py: 6,
                }}
            >
                {children}
            </Box>
        </Box>
    )
}
