import { createContext, useContext, useState } from 'react'
import data from '../config/CMS'

const AppState = createContext()

const AppStateProvider = ({ children }) => {
    const [plpMode, setPlpMode] = useState('display') // display | select
    const setSelectMode = () => setPlpMode('select')
    const setDisplayMode = () => {
        deselectAll()
        setPlpMode('display')
    }

    const [products, setProducts] = useState(data.products.map((item) => ({ ...item, selected: false })))

    const toggleProduct = (id) => {
        const cloned = [...products]
        const product = cloned.find((product) => product.id === id)
        product.selected = !product.selected
        setProducts(cloned)
    }

    const deselectAll = () => {
        const cloned = products.map((item) => ({ ...item, selected: false }))
        setProducts(cloned)
    }

    const selectAll = () => {
        const cloned = products.map((item) => ({ ...item, selected: true }))
        setProducts(cloned)
    }

    let selectedCount = 0
    products.forEach((product) => {
        if (product.selected) selectedCount++
    })

    return (
        <AppState.Provider
            value={{
                plpMode,
                setSelectMode,
                setDisplayMode,
                products,
                toggleProduct,
                selectAll,
                selectedCount,
            }}
        >
            {children}
        </AppState.Provider>
    )
}

const useAppState = () => {
    return useContext(AppState)
}

export { AppStateProvider, useAppState }
