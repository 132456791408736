import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useAppState } from '../../contexts/State'
import PDP from '../pdp/PDP'
import { ReactComponent as IconSelected } from '../../assets/IconSelected.svg'
import { ReactComponent as IconSelectable } from '../../assets/IconSelectable.svg'

export default function PLPProduct({ product }) {
    const state = useAppState()

    const [open, setOpen] = useState(false)

    const handleClick = () => {
        if (state.plpMode === 'display') {
            // openModal()
            setOpen(true)
        } else {
            state.toggleProduct(product.id)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <PDP
                open={open}
                handleClose={handleClose}
                product={product}
            />
            <Button
                sx={{
                    textTransform: 'none',
                    p: 0,
                    backgroundColor: product.selected ? 'secondary.100' : 'offWhite.main',
                    borderRadius: '10px',
                    position: 'relative',

                    '&:hover': {
                        backgroundColor: product.selected ? 'secondary.100' : 'offWhite.main',
                    },
                }}
                onClick={handleClick}
            >
                {state.plpMode === 'select' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                        }}
                    >
                        {product.selected ? <IconSelected /> : <IconSelectable />}
                    </Box>
                )}

                <Box
                    sx={{
                        height: '243px',
                        padding: '15px 20px 10px',
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: '15px',

                            '& img': {
                                display: 'block',
                                width: '170px',
                                height: '170px',
                            },
                        }}
                    >
                        <img src={product.image} />
                    </Box>
                    <Typography
                        variant="h4"
                        color="primary"
                        textAlign="center"
                        lineHeight={1}
                    >
                        {product.name}
                    </Typography>
                    {product.smallText && (
                        <Typography
                            variant="small"
                            color="primary"
                            textAlign="center"
                            component="div"
                            sx={{
                                mt: '5px',
                            }}
                        >
                            {product.smallText}
                        </Typography>
                    )}
                </Box>
            </Button>
        </>
    )
}
