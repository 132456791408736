import React, { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import data from '../../config/CMS'
import { useAppState } from '../../contexts/State'
import RoundButton from '../shared/RoundButton'
import FormModal from '../shared/FormModal'
import MaxWidth from '../shared/MaxWidth'

export default function PLPFooter() {
    const state = useAppState()

    const [modalOpen, setModalOpen] = useState(false)

    const popModal = () => {
        setModalOpen(true)
    }

    return (
        <Box
            sx={{
                borderTop: '1px solid',
                borderTopColor: 'primary.100',
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                minHeight: {
                    xs: 70,
                    md: 106,
                },
                backgroundColor: 'white',
                zIndex: 10,

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <MaxWidth>
                <Stack
                    justifyContent="center"
                    sx={{
                        py: {
                            xs: 1.5,
                            md: 2,
                        },
                    }}
                >
                    <FormModal
                        open={modalOpen}
                        handleClose={() => setModalOpen(false)}
                    />
                    {state.plpMode === 'display' && (
                        <Stack
                            spacing={{ xs: '8px', md: '10px' }}
                            sx={{
                                color: 'primary.main',
                            }}
                        >
                            <Typography
                                sx={{
                                    lineHeight: {
                                        xs: 1,
                                        md: 1.5,
                                    },
                                }}
                            >
                                {data.footer}
                            </Typography>
                            <Typography>{data.copyright}</Typography>
                        </Stack>
                    )}
                    {state.plpMode === 'select' && (
                        <>
                            {state.selectedCount < 1 && (
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        minHeight: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        color="primary"
                                    >
                                        Select items
                                    </Typography>
                                </Stack>
                            )}
                            {state.selectedCount >= 1 && (
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing="10px"
                                    sx={{
                                        minHeight: '100%',
                                    }}
                                >
                                    <RoundButton
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        onClick={popModal}
                                    >
                                        Send product detail sheets
                                    </RoundButton>
                                    <RoundButton
                                        size="large"
                                        color="primary"
                                        variant="outlined"
                                        onClick={state.setDisplayMode}
                                    >
                                        Cancel
                                    </RoundButton>
                                </Stack>
                            )}
                        </>
                    )}
                </Stack>
            </MaxWidth>
        </Box>
    )
}
