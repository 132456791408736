import { Button } from '@mui/material'
import React from 'react'

export default function RoundButton({ children, size, disableMinWidth, ...otherProps }) {
    const isLarge = size === 'large'

    return (
        <Button
            {...otherProps}
            disableElevation
            sx={{
                borderRadius: 50,
                fontFamily: "'Chobani Serif', sans-serif",
                textTransform: 'none',
                height: isLarge ? { xs: 36, md: 66 } : { xs: 36, md: 42 },
                fontSize: isLarge ? { xs: 14, md: 22 } : { xs: 14, md: 18 },
                minWidth: { xs: disableMinWidth ? 0 : 82, md: disableMinWidth ? 0 : 112 },
                px: isLarge ? { xs: '14px', md: '40px' } : { xs: '10px' },
            }}
        >
            {children}
        </Button>
    )
}
