const data = {
    mainTitle: <>Better Together</>,
    mainDescription: `Together, La Colombe and Chobani are committed to crafting a better 
        future for food and coffee through natural ingredients, less sugar, and delicious 
        taste that stands alone. Browse Chobani’s category-disrupting suite of dairy and 
        non-dairy products and La Colombe’s portfolio of innovative Draft Latte and cold 
        brew options.`,

    products: [
        {
            id: 'barista_oatmilk',
            name: 'Barista Oatmilk',
            image: '/images/products_expo24/BaristaOatmilk_Expo24.png',
            // smallText: 'Packaging art not final.',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Barista_v5-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Barista_v5-2.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Barista_v5.pdf',
        },
        {
            id: 'complete_drinks',
            name: 'Complete Drinks',
            image: '/images/products_expo24/CompleteDrinks_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-2.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-3.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-4.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-5.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1.pdf',
        },
        {
            id: 'creations',
            name: 'Creations™',
            image: '/images/products_expo24/Creations_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Creations_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Creations_v1-2.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Creations_v1',
        },
        {
            id: 'dairy_creamer',
            name: 'Dairy Creamer',
            image: '/images/products_expo24/DairyCreamer_Expo24.png',
            // smallText: 'Packaging art not final.',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Creamer_v2-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Creamer_v2-2.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Creamer_v2.pdf',
        },
        {
            id: 'flip',
            name: 'Flip®',
            image: '/images/products_expo24/Flip_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2-2.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2-3.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2-4.png',
            ],

            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Flip_v2.pdf',
        },
        {
            id: 'greek_yogurt',
            name: 'Greek Yogurt',
            image: '/images/products_expo24/GreekYogurt_Expo24.png',
            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-2.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-3.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-4.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1.pdf',
        },
        {
            id: 'greek_yogurt_drinks',
            name: 'Greek Yogurt Drinks',
            image: '/images/products_expo24/GreekYogurtDrinks_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-2.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-3.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-4.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-5.png',
            ],

            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1.pdf',
        },
        {
            id: 'less_sugar',
            name: 'Less Sugar*',
            image: '/images/products_expo24/LessSugar_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_LSG_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_LSG_v1-2.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_LSG_v1.pdf',
        },
        {
            id: 'multi_serve',
            name: 'Multi-Serve',
            image: '/images/products_expo24/MultiServe_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-2.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-3.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1-4.png',
            ],

            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Core_v1.pdf',
        },
        {
            id: 'oatmilk',
            name: 'Oatmilk',
            image: '/images/products_expo24/Oatmilk_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Oatmilk_v3-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Oatmilk_v3-2.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Oatmilk_v3.pdf',
        },
        {
            id: 'oatmilk_limited_batch',
            name: 'Oatmilk Limited Batch',
            image: '/images/products_expo24/CRLMK_Oatmilk_SCA24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-1.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-2.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-3.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-4.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-5.png',
                '/images/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk-6.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Sell-Sheets_Oatmilk.pdf',
        },
        {
            id: 'zero_sugar',
            name: 'Zero Sugar**',
            image: '/images/products_expo24/ZeroSugar_Expo24.png',

            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_ZS_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_ZS_v1-2.png',
            ],

            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_ZS_v1.pdf',
        },
        {
            id: 'zero_sugar_drinks',
            name: 'Zero Sugar** Drinks',
            image: '/images/products_expo24/ZeroSugarDrinks_Expo24.png',
            sheets: [
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-1.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-2.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-3.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-4.png',
                '/images/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1-5.png',
            ],
            pdf: '/sheets/SALES_TM_2024_Expo-West-Sell-Sheets_Drinks_v1.pdf',
        },

        {
            id: 'lc_draft_latte_11oz',
            name: 'Draft Latte 11oz',
            image: '/images/products_la_colombe/LC_11oz_Triple_Expo24.png',

            sheets: [
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_11oz_seasonal-1.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_11oz_seasonal-2.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_11oz_seasonal-3.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_11oz_seasonal-4.png',
            ],

            pdf: '/sheets/LC_EXPOWEST_SELLSHEET_11oz_.pdf',
        },
        {
            id: 'lc_draft_latte_9oz',
            name: 'Draft Latte 9oz',
            image: '/images/products_la_colombe/LC_Triple Draft Latte 9oz Can Front.png',

            sheets: [
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_9oz_seasonal-1.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_9oz_seasonal-2.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_9oz_seasonal-3.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_9oz_seasonal-4.png',
            ],

            pdf: '/sheets/LC_EXPOWEST_SELLSHEET_9oz_.pdf',
        },
        {
            id: 'lc_draft_latte_4pk',
            name: 'Draft Latte 4pk',
            image: '/images/products_la_colombe/LC_9oz_4Pack_TripleDraftLatte_Expo24.png',

            sheets: [
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_4PACK_seasonal-1.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_4PACK_seasonal-2.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_4PACK_seasonal-3.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_4PACK_seasonal-4.png',
            ],

            pdf: '/sheets/LC_EXPOWEST_SELLSHEET_4PACK_.pdf',
        },
        {
            id: 'lc_cold_brew_42oz',
            name: 'Cold Brew 42oz',
            image: '/images/products_la_colombe/LC_42oz_Comp_MediumUnsweet_Expo24.png',

            sheets: [
                '/images/sheets_la_colombe/LC-EXPOWEST-SELLSHEET_42ozMULTISERVE-1.png',
                '/images/sheets_la_colombe/LC-EXPOWEST-SELLSHEET_42ozMULTISERVE-2.png',
            ],

            pdf: '/sheets/LC-EXPOWEST-SELLSHEET_42ozMULTISERVE.pdf',
        },
        {
            id: 'lc_coffee_beans',
            name: 'Roasted Coffee',
            image: '/images/products_la_colombe/LC_New_12oz_Packaging_redesign_cutouts_Corsica_Expo24.png',

            sheets: [
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_ROASTED_v2-1.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_ROASTED_v2-2.png',
            ],

            pdf: '/sheets/LC_EXPOWEST_SELLSHEET_ROASTED_v2.pdf',
        },
        {
            id: 'lc_k_cup_pods',
            name: 'K-Cup Pods',
            image: '/images/products_la_colombe/LC_Corsica_K-Cup_Amzn3Q_Expo24.png',

            sheets: [
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_KCUP_-1.png',
                '/images/sheets_la_colombe/LC_EXPOWEST_SELLSHEET_KCUP_-2.png',
            ],

            pdf: '/sheets/LC_EXPOWEST_SELLSHEET_KCUP_.pdf',
        },
    ],

    footer: (
        <>
            *Chobani® Less Sugar Greek Yogurt: 9g sugar per 5.3oz; similar yogurts without sugar substitutes: avg. 16g
            sugar per 5.3oz
            <br />
            **Not a low calorie food
        </>
    ),

    copyright: '© March 2024 Chobani, LLC. All Rights Reserved.',
}

export default data
