import React from 'react'
import { Stack, Box, Button, Typography } from '@mui/material'
import { useAppState } from '../../contexts/State'
import { ReactComponent as IconBack } from '../../assets/IconBack.svg'
import RoundButton from '../shared/RoundButton'
import MaxWidth from '../shared/MaxWidth'

export default function PDPHeader({ name, handleClose }) {
    const state = useAppState()

    return (
        <Box
            sx={{
                borderBottom: '1px solid',
                borderBottomColor: 'primary.100',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                backgroundColor: 'white',
                zIndex: 10,
            }}
        >
            <MaxWidth>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        py: 2,
                    }}
                >
                    <RoundButton
                        startIcon={<IconBack />}
                        onClick={handleClose}
                        color="primary"
                        disableMinWidth
                    >
                        <Box
                            component="span"
                            sx={{
                                display: {
                                    xs: 'none',
                                    lg: 'block',
                                },
                            }}
                        >
                            Back to all products
                        </Box>
                    </RoundButton>

                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <Typography
                            variant="h2"
                            color="primary"
                            textAlign="center"
                        >
                            {name}
                        </Typography>
                    </Box>
                </Stack>
            </MaxWidth>
        </Box>
    )
}
