import { Box, Typography } from '@mui/material'
import React from 'react'
import data from '../../config/CMS'
import { useAppState } from '../../contexts/State'
import Main from '../layout/Main'
import MaxWidth from '../shared/MaxWidth'
import PLPFooter from './PLPFooter'
import PLPHeader from './PLPHeader'
import PLPProduct from './PLPProduct'

export default function PLP() {
    const state = useAppState()

    return (
        <Box>
            <PLPHeader />

            <MaxWidth>
                <Main>
                    <Box
                        sx={{
                            display: 'grid',
                            gap: '28px',
                            gridTemplateColumns: {
                                xs: 'none',
                                sm: 'repeat(2, 1fr)',
                                md: 'repeat(3, 1fr)',
                                lg: 'repeat(4, 1fr)',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                gridColumn: { xs: 1, sm: '1 / 3' },
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: {
                                        xs: '100%',
                                        lg: 400,
                                    },
                                }}
                            >
                                <Typography
                                    color="primary"
                                    variant="h1"
                                    sx={{
                                        mb: '28px',
                                    }}
                                >
                                    {data.mainTitle}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                >
                                    {data.mainDescription}
                                </Typography>
                            </Box>
                        </Box>
                        {state.products.map((product, index) => (
                            <PLPProduct
                                key={index}
                                product={product}
                            />
                        ))}
                    </Box>
                </Main>
            </MaxWidth>

            <PLPFooter />
        </Box>
    )
}
